import { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	TextField,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import axios from 'axios';
import { API_GetEmployees } from '../Assets/ApiUrlContainer';

const UserManagementPage = () => {
	const [users, setUsers] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [roleFilter, setRoleFilter] = useState('');

	// Fetch users from the backend
	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await axios.get(API_GetEmployees);

				setUsers(response.data);
			} catch (error) {
				console.error('Error fetching users:', error);
			}
		};

		fetchUsers();
	}, []);

	return (
		<div className='tgs-content'>
			<Box sx={{ padding: '16px' }}>
				<Typography variant='h4' gutterBottom>
					User Management
				</Typography>
				<Typography variant='body2' color='textSecondary' gutterBottom>
					Look up users, add or remove them, and change their roles. Access to user management is restricted
					to admins only.
				</Typography>

				{/* Search and Role Filter */}
				<Box sx={{ display: 'flex', gap: 2, my: 2 }}>
					<TextField
						label='Search'
						variant='outlined'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						fullWidth
					/>
					<FormControl variant='outlined' sx={{ minWidth: 120 }}>
						<InputLabel>Role</InputLabel>
						<Select value={roleFilter} onChange={(e) => setRoleFilter(e.target.value)} label='Role'>
							<MenuItem value=''>
								<em>All</em>
							</MenuItem>
							<MenuItem value='Admin'>Admin</MenuItem>
							<MenuItem value='Recruiter'>Recruiter</MenuItem>
						</Select>
					</FormControl>
					<Button variant='contained' color='primary' startIcon={<Add />}>
						Add User
					</Button>
				</Box>

				{/* User Table */}
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>User</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Role</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users
								.filter((user) => user.name.toLowerCase().includes(searchTerm.toLowerCase()))
								.filter((user) => (roleFilter ? user.role === roleFilter : true))
								.map((user) => (
									<TableRow key={user.email}>
										<TableCell>
											{user.name} {user.email === 'your-email@example.com' ? '(You)' : ''}
										</TableCell>
										<TableCell>{user.email}</TableCell>
										<TableCell>{user.role}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</div>
	);
};

export default UserManagementPage;
