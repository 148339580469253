import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ModalPrompt } from './ModalPrompt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {
	Box,
	Typography,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	TextField,
	Select,
	Chip,
	MenuItem as MuiMenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { EllipsisStatusMenu } from './EllipsisStatusContent';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';
import { AppliedIcon, Interview1Icon, Interview2Icon, Interview3Icon } from './CustomIcons';
import { Pagination } from './Pagination';
import {
	API_CreateJobApplication,
	API_GetJob,
	API_GetJobApplications,
	API_GetJobPersonaDetails,
	API_UpdateCandidateInterviewStage,
	API_UpdateJobPersona,
	API_UpdatePersonaSkills,
} from '../Assets/ApiUrlContainer';

const JobDetailsPage = () => {
	const { id } = useParams();
	const [job, setJob] = useState(null);
	const [applicants, setApplicants] = useState([]);
	const [persona, setPersona] = useState([]);
	const [skills, setSkills] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedApplicant, setSelectedApplicant] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedStatus, setSelectedStatus] = useState('');

	const [allPersonas, setAllPersonas] = useState([]);
	const [personaAllSkills, setPersonaAllSkills] = useState([]);
	const [personaDescriptionFilteredSkills, setPersonaDescriptionFilteredSkills] = useState([]);

	const [categorizedApplicants, setCategorizedApplicants] = useState(null);
	const [totalFilteredApplicants, setTotalFilteredApplicants] = useState(0);

	const [modalIsPersonaReplacement, setModalIsPersonaReplacement] = useState(false);
	const [replaceModalToggleState, setReplaceModalToggleState] = useState(false);
	const [modalReplacementPersona, setModalReplacementPersona] = useState(null);
	const [canReplaceModal, setCanReplaceModal] = useState(false);
	const [replacementSkills, setReplacementSkills] = useState([]);
	const [skillsAvailableToSelect, setSkillsAvailableToSelect] = useState([]);
	const [canPersonaSaveSkills, setCanPersonaSaveSkills] = useState([]);
	const [skillEntryText, setSkillEntryText] = useState('');

	const [listDisplayStartingIndex, setListDisplayStartingIndex] = useState(0);
	const [listDisplayLimit, setListDisplayLimit] = useState(0);

	const [openEllipsisMenuApplicationId, setOpenEllipsisMenuApplicationId] = useState(null);
	const [ellipsisOverrideStyles, setEllipsisOverrideStyles] = useState(null);

	useEffect(() => {
		fetchAllJobContent();
	}, [id]);

	useEffect(() => {
		let applicantsFiltered = applicants.filter((applicant) => {
			const matchesSearch = applicant.fullName.toLowerCase().includes(searchQuery.toLowerCase());
			const matchesStatus = !selectedStatus || applicant.state === selectedStatus;
			return matchesSearch && matchesStatus;
		});

		let applicantsCategorized = applicantsFiltered.reduce((acc, applicant) => {
			if (!acc[applicant.state]) {
				acc[applicant.state] = [];
			}
			acc[applicant.state].push(applicant);
			return acc;
		}, {});

		setCategorizedApplicants(applicantsCategorized);
		setTotalFilteredApplicants(applicantsFiltered.length);
	}, [applicants]);

	const fetchAllJobContent = async () => {
		try {
			// Fetch job, applicants, and persona/skills data
			fetchJobDetails();
			fetchApplicantDetails();
			fetchPersonaDetails();
			setLoading(false);
		} catch (error) {
			console.error('Error fetching job content:', error);
			setLoading(false);
		}
	};

	const fetchJobDetails = async () => {
		const jobResponse = await axios.get(`${API_GetJob}/${id}`);
		setJob(jobResponse.data);
	};

	const fetchApplicantDetails = async () => {
		const applicantsResponse = await axios.get(`${API_GetJobApplications}/${id}`);
		const applicantsWithFields = applicantsResponse.data.map((applicant) => ({
			...applicant,
			state: applicant.state,
			label: applicant.label,
		}));

		setApplicants(applicantsWithFields);
		setPersonaDescriptionFilteredSkills(
			applicantsWithFields.length > 0 ? applicantsWithFields[0].personaSkills : []
		);
	};

	const fetchPersonaDetails = async () => {
		const personaSkillsResponse = await axios.get(`${API_GetJobPersonaDetails}/${id}`);
		setPersona(personaSkillsResponse.data.persona);
		setSkills(personaSkillsResponse.data.skills);
		setAllPersonas(personaSkillsResponse.data.allPersonas);
		setPersonaAllSkills(personaSkillsResponse.data.allPersonaMatchingSkills);
	};

	const handleCloseEllipsisMenu = () => {
		setOpenEllipsisMenuApplicationId(null);
		setSelectedApplicant(null);
		setEllipsisOverrideStyles(null);
	};

	const handleEllipsisOptionSelected = async (selectedEllipsisOption) => {
		let changeType = selectedEllipsisOption.menuItem;
		let newItem = selectedEllipsisOption.listItem;

		if (changeType === 'Status') {
			await handleStatusChange(newItem);
		} else {
			await handleLabelChange(newItem);
		}

		fetchApplicantDetails();
		handleCloseEllipsisMenu();
	};

	const handleMenuClick = (event, applicant) => {
		setOpenEllipsisMenuApplicationId(applicant.applicationId);
		setSelectedApplicant(applicant);

		// these are relative to the viewport, i.e. the window
		let offsetTop = event.currentTarget.offsetTop;
		let offsetLeft = event.currentTarget.offsetLeft;

		var top = `${offsetTop - 48}px`;
		var left = `${offsetLeft - 120}px`;
		let ellipsisStyles = {
			right: 'initial',
			bottom: 'initial',
			top,
			left,
		};

		setEllipsisOverrideStyles(ellipsisStyles);
	};

	const handleStatusChange = async (newState) => {
		if (selectedApplicant) {
			try {
				await axios.put(API_UpdateCandidateInterviewStage, {
					applicationId: selectedApplicant.applicationId,
					state: newState,
					label: selectedApplicant.label,
				});
			} catch (error) {
				console.error('Error updating status:', error);
				alert('Failed to update status. Please try again.');
			}
		}
	};

	const handleLabelChange = async (newLabel) => {
		if (selectedApplicant) {
			try {
				await axios.put(API_UpdateCandidateInterviewStage, {
					applicationId: selectedApplicant.applicationId,
					state: selectedApplicant.state,
					label: newLabel,
				});
			} catch (error) {
				console.error('Error updating label:', error);
				alert('Failed to update label. Please try again.');
			}
		}
	};

	const handleSearchChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const handleStatusFilter = (event) => {
		setSelectedStatus(event.target.value);
	};

	const ProgressCircle = ({ category }) => {
		if (category === 'Hired') {
			return <CheckCircle sx={{ color: 'green' }} />;
		} else if (category === 'Rejected') {
			return <CancelIcon sx={{ color: 'red' }} />;
		} else if (category === 'Applied' || category === 'Matched') {
			return <AppliedIcon />;
		} else if (category === 'Interview 1') {
			return <Interview1Icon />;
		} else if (category === 'Interview 2') {
			return <Interview2Icon />;
		} else if (category === 'Interview 3') {
			return <Interview3Icon />;
		}
		return null;
	};

	const handlePageChange = (newPositionIndex) => setListDisplayStartingIndex(newPositionIndex);
	const handlePageListLimitChange = (newListDisplayLimit) => setListDisplayLimit(newListDisplayLimit);

	// Function to render the label chip
	const renderLabel = (label) => {
		// Do not render anything if there's no label
		if (!label) return null;

		let chipColor = ''; // Default empty
		let chipLabel = label || 'N/A'; // Default label if none

		// Determine the chip color based on label
		if (label === 'Interviewed') {
			chipColor = 'green';
		} else if (label === 'Interview Scheduled') {
			chipColor = 'orange';
		}

		return (
			<Chip
				label={chipLabel}
				style={{
					backgroundColor: 'transparent', // No background color for the chip
					color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
					fontFamily: 'var(--fontFamily, Roboto)', // Apply the font family
					fontSize: 'var(--font-size-08125-rem, 13px)', // Apply the font size
					fontWeight: 'var(--fontWeightRegular, 400)', // Apply the font weight
					lineHeight: '18px', // Set line height
					letterSpacing: '0.16px', // Set letter spacing
					padding: '4px 10px',
					borderColor: 'grey', // Grey border
					borderWidth: '1px',
					borderStyle: 'solid',
				}}
				icon={
					chipColor ? (
						<span
							style={{
								width: '10px',
								height: '10px',
								borderRadius: '50%',
								backgroundColor: chipColor, // Set color of the dot
								marginRight: '8px', // Space between dot and label text
							}}
						/>
					) : null
				}
			/>
		);
	};

	if (loading) {
		return <CircularProgress />;
	}

	if (!job) {
		return <Typography>Job not found</Typography>;
	}

	const totalApplicantsCount = applicants.length; // The total count remains the same

	const getDateString = (dateString) => {
		let date = new Date(dateString);
		let month = date.toLocaleString('default', { month: 'short' });
		let day = date.getDate();
		let year = date.getFullYear();

		let hour = date.getHours() % 12;
		let minutes = date.getMinutes();

		return `${month} ${day}, ${year} ${hour}:${minutes}`;
	};

	const handleModalClose = () => {
		setReplaceModalToggleState(false);
		setModalReplacementPersona(null);
		setReplacementSkills(skills);
		setCanPersonaSaveSkills(false);
		setSkillEntryText('');
	};

	const handleOpenModal = () => {
		setReplaceModalToggleState(!replaceModalToggleState);
	};

	const handleOpenSkillsModal = () => {
		setModalIsPersonaReplacement(false);
		setReplacementSkills(persona[0].RelevantSkills.split(', '));
		setSkillsAvailableToSelect(personaAllSkills.filter((skill) => !persona[0].RelevantSkills.includes(skill.Name)));
		handleOpenModal();
	};

	const handleOpenPersonaModal = () => {
		setModalIsPersonaReplacement(true);
		handleOpenModal();
	};

	const handlePersonaSelected = (event) => {
		let personaId = Number(event.target.value);
		let selectedPersona = allPersonas.find((persona) => persona.ID === personaId);
		setModalReplacementPersona(selectedPersona);
		setCanReplaceModal(selectedPersona.ID !== persona.ID);
	};

	const handleReplaceModalEntry = async () => {
		let personaSkillsResponse = await axios.put(`${API_UpdateJobPersona}/${id}/${modalReplacementPersona.ID}`);
		setPersona(personaSkillsResponse.data.persona);
		setSkills(personaSkillsResponse.data.skills);
		setPersonaAllSkills(personaSkillsResponse.data.allPersonaMatchingSkills);
		setReplacementSkills(personaSkillsResponse.data.persona[0].RelevantSkills.split(', '));

		fetchApplicantDetails();
		handleModalClose();
	};

	const removeSkillFromList = (skillName) => {
		let relevantSkills = [...replacementSkills];
		let indexSkill = relevantSkills.findIndex((skill) => skill.toLowerCase() === skillName.toLowerCase());

		let newSkillsList = [
			...relevantSkills.slice(0, indexSkill),
			...relevantSkills.slice(indexSkill + 1, relevantSkills.length),
		];

		setReplacementSkills(newSkillsList);
		setSkillsAvailableToSelect(personaAllSkills.filter((skill) => !newSkillsList.join(', ').includes(skill.Name)));
		setCanPersonaSaveSkills(newSkillsList.length > 0);
	};

	const addSkillToList = () => {
		let skills = [...replacementSkills];
		if (!skills.some((skill) => skill.toLowerCase() === skillEntryText.toLowerCase())) {
			skills.push(skillEntryText.trim());

			setReplacementSkills(skills);
			setSkillsAvailableToSelect(personaAllSkills.filter((skill) => !skills.join(', ').includes(skill.Name)));
			setCanPersonaSaveSkills(true);
		}
	};

	const handleSkillTextEntry = (event) => {
		setSkillEntryText(event.target.value);
	};

	const handleSkillEntryEnterKey = (event) => {
		if (event.key === 'Enter') {
			addSkillToList();
			setSkillEntryText('');
		}
	};

	const handleReplaceModalSkills = async () => {
		let oldPersonaSkills = persona[0].RelevantSkills.split(', ');
		let newPersonaSkills = replacementSkills;

		let oldSkillsToRemove = [];
		let newSkillsToAssign = [];
		let newSkillsToCreate = [];

		oldPersonaSkills.forEach((oldSkill) => {
			if (!newPersonaSkills.includes(oldSkill)) {
				oldSkillsToRemove.push({
					Persona_ID: persona[0].ID,
					Skill_ID: personaAllSkills.find((skill) => skill.Name.toLowerCase() === oldSkill.toLowerCase()).ID,
				});
			}
		});

		newPersonaSkills.forEach((newSkill) => {
			if (!oldPersonaSkills.includes(newSkill)) {
				let skill = personaAllSkills.find(
					(skill) => skill.Name === newSkill && skill.JobType_ID === persona[0].RoleID
				);

				if (skill) {
					newSkillsToAssign.push({ Persona_ID: persona[0].ID, Skill_ID: skill.ID });
				} else {
					newSkillsToCreate.push(newSkill);
				}
			}
		});

		let currentDate = new Date();
		let formattedDate = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${(
			'0' + currentDate.getDate()
		).slice(-2)}`;

		let personaChanges = {
			PersonaID: persona[0].ID,
			SkillsToRemove: oldSkillsToRemove,
			SkillsToAssign: newSkillsToAssign,
			SkillsToCreate: newSkillsToCreate,
			LastUpdated: formattedDate,
			RoleName: persona[0].RoleName,
		};

		await axios.put(API_UpdatePersonaSkills, personaChanges);
		fetchPersonaDetails();
		handleModalClose();
	};

	const getSkillNeedsIndicationRelevant = (personaSkillName) => {
		return personaDescriptionFilteredSkills.includes(personaSkillName.toLowerCase());
	};

	const handleCreateApplication = async (applicant) => {
		try {
			await axios.post(API_CreateJobApplication, {
				candidateID: applicant.candidateID,
				jobID: id,
			});

			fetchApplicantDetails();
		} catch (error) {
			console.log(error);
		}
	};

	// return (
	//   <>
	//	<div className='tgs-content'>
	//     <div className="jobDetailsContainer">
	//       <div className="jobInformationContainer">
	//         <div className="jobInfoDisplay">

	//           <div className="heading">
	//             <div className="jobTitle">
	//               <div className="titleText">{job.title}</div>
	//               <span>{job.currentStatus}</span>
	//             </div>
	//             <div className="lastSyncStatus">Last synced: {getDateString(new Date(job.lastSynced))}</div>
	//           </div>

	//           <div className="jobPersonaDetails">
	//             <div className="personaType">
	//               <div className="personaTitle">Persona</div>
	//               <div className="personaTitleName">N/A</div>
	//             </div>

	//             <div className="personaSkills">
	//               <div className="skillsTitle">Skills</div>
	//               <div className="skillsList">N/A</div>
	//             </div>
	//           </div>

	//           <div className="details">
	//             <div className="detailsTitle">Details</div>
	//             <div className='detailsLocation'>{job.location}</div>
	//             <div className='detailsJobType'>{job.type}</div>
	//           </div>

	//           <div className="jobDescription">
	//             <div className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job.description)}}></div>
	//           </div>
	//         </div>
	//       </div>

	//       <div className="content-divider"><div className="separator"></div></div>

	//       <div className="jobApplicants">
	//         <div className="applicantTitle">Applicants ({applicants.length})</div>

	//         <div className="applicantTopBar">

	//         </div>
	//       </div>
	//     </div>
	//    </div>
	//   </>
	// )

	return (
		<>
			<div className='tgs-content'>
				<Box sx={{ height: '65vh', display: 'flex', flexDirection: 'column' }}>
					{/* Top Section */}
					<Box sx={{ flex: 1, overflowY: 'hidden', borderBottom: '1px solid #e0e0e0', display: 'flex' }}>
						{/* Left Section (Job Details) */}
						<Box sx={{ flex: 1, padding: '16px', overflowY: 'auto', maxHeight: 'calc(100vh - 32px)' }}>
							<Typography variant='h4'>{job.title}</Typography>
							<Typography variant='subtitle2' color='textSecondary'>
								Last synced: {getDateString(new Date(job.lastSynced))}
							</Typography>
							<Typography variant='body2' color='textSecondary' sx={{ marginTop: '8px' }}>
								{job.location} - {job.type}
							</Typography>
							<Typography variant='h6' sx={{ marginTop: '16px' }}>
								Summary
							</Typography>

							<Box dangerouslySetInnerHTML={{ __html: job.description }} />
						</Box>

						{/* Right Section (Persona and Skills) */}
						<Box
							sx={{
								width: '30%',
								padding: '16px',
								borderLeft: '1px solid #e0e0e0',
								position: 'sticky',
								top: 0,
								height: '100vh', // Ensure the right side stays in place
								overflowY: 'auto', // Allow scrolling if content overflows
							}}
						>
							<Typography variant='h6'>
								<span className='personaSidePanelText'>Persona</span>
							</Typography>
							<Typography variant='body2'>
								<span className='personaSidePanelText'>
									{persona.length > 0 ? persona[0].Name : 'N/A'}
								</span>
								<span className='replacePersonaBtn' onClick={handleOpenPersonaModal}>
									Replace
								</span>
							</Typography>
							<Typography variant='h6' sx={{ marginTop: '16px' }}>
								<span className='personaSidePanelText'>Skills</span>
								<span className='replacePersonaSkillsBtn' onClick={handleOpenSkillsModal}>
									Edit
								</span>
							</Typography>
							<div className='skillEntry'>
								{skills.length > 0 ? (
									skills.map((skill) => (
										<div
											key={skill}
											className={`skillEntryBubble ${
												getSkillNeedsIndicationRelevant(skill) ? 'relevantSkillBubble' : ''
											}`}
										>
											{skill}
										</div>
									))
								) : (
									<Typography variant='body2'>No skills available</Typography>
								)}
							</div>
						</Box>
					</Box>
				</Box>
			</div>

			<div className='tgs-content'>
				<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
					{/* Bottom Section */}
					<Box sx={{ flex: 1, padding: '16px' }}>
						<Typography variant='h6' sx={{ marginBottom: '16px' }}>
							Applicants ({totalFilteredApplicants})
						</Typography>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								width: '50%',
								gap: '16px',
								marginBottom: '5px',
							}}
						>
							{/* Search Field */}
							<TextField
								label='Search'
								value={searchQuery}
								onChange={handleSearchChange}
								variant='outlined'
								fullWidth
								sx={{
									'& .MuiInputBase-root': {
										height: '46px', // Adjust height for vertical alignment
										padding: '0 12px', // Adjust horizontal padding
									},
									'& .MuiOutlinedInput-input': {
										padding: '12px 0', // Adjust padding to center placeholder text vertically
									},
									'& .MuiInputLabel-root': {
										paddingBottom: '16px', // Adjust label position
									},
									backgroundColor: '#f5f5f5',
									flex: 1, // Take up remaining space
								}}
							/>

							{/* Status Filter */}
							<Select
								value={selectedStatus}
								onChange={handleStatusFilter}
								displayEmpty
								fullWidth
								sx={{
									'& .MuiSelect-select': {
										height: '16px', // Adjust vertical height
									},
									'& .MuiInputBase-root': {
										height: '60px', // Adjust vertical height
									},
									'& .MuiOutlinedInput-input': {
										padding: '12px 12px', // Adjust horizontal padding
									},
									backgroundColor: '#f5f5f5',
									flex: 1, // Take up remaining space
								}}
							>
								<MuiMenuItem value=''>Status</MuiMenuItem>
								<MuiMenuItem value='Applied'>Applied</MuiMenuItem>
								<MuiMenuItem value='Interview 1'>Interview 1</MuiMenuItem>
								<MuiMenuItem value='Interview 2'>Interview 2</MuiMenuItem>
								<MuiMenuItem value='Interview 3'>Interview 3</MuiMenuItem>
								<MuiMenuItem value='Hired'>Hired</MuiMenuItem>
								<MuiMenuItem value='Matched'>Matched</MuiMenuItem>
								<MuiMenuItem value='Rejected'>Rejected</MuiMenuItem>
							</Select>
						</Box>

						{/* Table Headers (only appear once) */}
						<Table sx={{ tableLayout: 'fixed' }}>
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: '25%', padding: '8px', fontWeight: 'bold' }}>
										Name
									</TableCell>
									<TableCell sx={{ width: '10%', padding: '8px', fontWeight: 'bold' }}>
										Match
									</TableCell>
									<TableCell sx={{ width: '30%', padding: '8px', fontWeight: 'bold' }}>
										Email
									</TableCell>
									<TableCell sx={{ width: '20%', padding: '8px', fontWeight: 'bold' }}>
										Label
									</TableCell>
									<TableCell sx={{ width: '10%', padding: '8px', fontWeight: 'bold' }}>
										Status
									</TableCell>
									<TableCell sx={{ width: '5%', padding: '8px', fontWeight: 'bold' }}>
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
						</Table>

						{Object.keys(categorizedApplicants).map((category, index) => (
							<div key={index}>
								{/* Render category header with gray background */}
								<Box
									sx={{
										backgroundColor: '#f5f5f5',
										padding: '8px',
										marginBottom: '8px',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									{/* Add the icon before the category name */}
									<ProgressCircle category={category} />
									<Typography variant='h6' sx={{ marginLeft: '8px' }}>
										{category}
									</Typography>
								</Box>
								{categorizedApplicants[category].length > 0 && ( // Only show non-empty categories
									<Box sx={{ marginBottom: '16px' }}>
										<TableContainer component={Paper}>
											<Table sx={{ tableLayout: 'fixed' }}>
												<TableBody>
													{categorizedApplicants[category]
														.slice(listDisplayStartingIndex, listDisplayLimit)
														.map((applicant, index) => (
															<TableRow key={index}>
																<TableCell sx={{ width: '25%', padding: '8px' }}>
																	<Link
																		to={`/applicants/${encodeURIComponent(
																			applicant.email
																		)}`}
																	>
																		{applicant.fullName}
																	</Link>
																</TableCell>
																<TableCell
																	sx={{
																		width: '10%',
																		color:
																			applicant.matchPercentage < 50
																				? 'red'
																				: applicant.matchPercentage >= 50
																				? 'green'
																				: 'black',
																		fontWeight: 'bold',
																	}}
																>
																	{applicant.matchPercentage}%
																</TableCell>
																<TableCell sx={{ width: '30%', padding: '8px' }}>
																	{applicant.email}
																</TableCell>
																<TableCell sx={{ width: '20%', padding: '8px' }}>
																	{renderLabel(applicant.label)}
																</TableCell>
																<TableCell sx={{ width: '10%', padding: '8px' }}>
																	{applicant.state}
																</TableCell>
																<TableCell
																	sx={{
																		width: '5%',
																		padding: '8px',
																		textAlign: 'center',
																	}}
																>
																	{applicant.applicationId && (
																		<IconButton
																			onClick={(e) =>
																				handleMenuClick(e, applicant)
																			}
																			sx={{
																				backgroundColor: 'transparent',
																				position: 'relative',
																			}}
																		>
																			<MoreVertIcon />
																		</IconButton>
																	)}

																	{!applicant.applicationId && (
																		<IconButton
																			title='Create an application'
																			onClick={() =>
																				handleCreateApplication(applicant)
																			}
																		>
																			<div className='createApplicationIcon'>
																				<FontAwesomeIcon icon={faPlus} />
																			</div>
																		</IconButton>
																	)}
																</TableCell>
															</TableRow>
														))}
												</TableBody>
											</Table>
										</TableContainer>
									</Box>
								)}
							</div>
						))}

						<Pagination
							totalNumItems={totalApplicantsCount}
							onPageChangeNewPosition={handlePageChange}
							onRowsChangeNewDisplayLimit={handlePageListLimitChange}
						/>
					</Box>
				</Box>

				{selectedApplicant && (
					<EllipsisStatusMenu
						openEllipsisMenu={openEllipsisMenuApplicationId === selectedApplicant.applicationId}
						onCloseEllipsis={handleCloseEllipsisMenu}
						onItemSelected={handleEllipsisOptionSelected}
						overrideStyle={ellipsisOverrideStyles}
					/>
				)}
			</div>

			<ModalPrompt
				onModalClose={handleModalClose}
				modalToggleState={replaceModalToggleState}
				modalTitle={modalIsPersonaReplacement ? 'Replace Persona' : 'Edit Skills'}
				modalContent={
					<>
						{modalIsPersonaReplacement ? (
							<div className='replacePersonaModal'>
								<div className='replacePersonaMsg'>
									Replacing the persona will change the skills assigned to this job.
								</div>

								<div className='personaSelector'>
									<span>Select Persona</span>
									<select
										id='personaSelect'
										name='personaList'
										onChange={handlePersonaSelected}
										defaultValue={persona.length > 0 ? persona[0].ID : null}
									>
										{allPersonas.length > 0 &&
											allPersonas.map((personaEntry) => (
												<option key={personaEntry.ID} value={personaEntry.ID}>
													{personaEntry.Name}
												</option>
											))}
									</select>
								</div>

								<div className='modalBtns'>
									<button className='cancelBtn' onClick={handleModalClose}>
										CANCEL
									</button>
									<button
										className='confirmBtn'
										onClick={handleReplaceModalEntry}
										disabled={!canReplaceModal}
									>
										CONFIRM
									</button>
								</div>
							</div>
						) : (
							<div className='replacePersonaModal'>
								<div className='replacePersonaMsg'>
									Edit the required skills to rank applicants based on their skill match.
								</div>

								<div className='personaSkills'>
									<span>Skills</span>
									<div className='skillEntry'>
										{replacementSkills.map((skill) => (
											<div className='skillEntryBubble' key={skill}>
												<span>{skill}</span>
												<FontAwesomeIcon
													icon={faCircleXmark}
													onClick={() => removeSkillFromList(skill)}
												/>
											</div>
										))}
									</div>
									<input
										type='text'
										list='skillsList'
										value={skillEntryText}
										onInput={handleSkillTextEntry}
										onKeyDown={handleSkillEntryEnterKey}
									/>
									<datalist id='skillsList'>
										{skillsAvailableToSelect.map((skill) => (
											<option key={skill.ID}>{skill.Name}</option>
										))}
									</datalist>
								</div>

								<div className='modalBtns'>
									<button className='cancelBtn' onClick={handleModalClose}>
										CANCEL
									</button>
									<button
										className='confirmBtn'
										onClick={handleReplaceModalSkills}
										disabled={!canPersonaSaveSkills}
									>
										CONFIRM
									</button>
								</div>
							</div>
						)}
					</>
				}
			/>
		</>
	);
};

export default JobDetailsPage;
