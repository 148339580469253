import React, { useState, useEffect } from "react";

export const ModalViewPersona = ({ persona, onModalEditClick, onModalClose }) => {
    const [skillsList, setSkillsList] = useState([]);
    useEffect(() => {
        setSkillsList(persona.RelevantSkills.split(", "))
    }, [persona, setSkillsList])
    
    return (
        <>
            <div className="modalViewPersona">
                <div className="roleContainer">
                    <div className="label">Role</div>
                    <div className="entry">{persona.RoleName}</div>
                </div>

                <div className="industryContainer">
                    <div className="label">Industry</div>
                    <div className="entry">{persona.IndustryName}</div>
                </div>

                <div className="skillsContainer">
                    <div className="label">Skills</div>
                    <div className="skillEntry">
                        {skillsList.map(skill => (
                            <div key={skill} className="skillEntryBubble">{skill}</div>
                        ))}
                    </div>
                </div>

                <div className="modalBtns">
                    <button className="editBtn" onClick={onModalEditClick}>EDIT</button>
                    <button className="closeBtn" onClick={onModalClose}>CLOSE</button>
                </div>
            </div>
        </>
    )
};