import React from "react";
import { Modal, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const ModalPrompt = ({ onModalClose, modalToggleState, modalTitle, modalContent }) => {
    return (
        <Modal
            open={modalToggleState}
            onClose={onModalClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box>
                <div className="closeModalBtn" onClick={onModalClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <div id="modal-modal-title">{modalTitle}</div>
                <div id="modal-modal-description">{modalContent}</div>
            </Box>
        </Modal>
    );
};