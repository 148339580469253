import { useEffect, useState } from 'react';
import { Pagination } from './Pagination';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	TextField,
	Box,
} from '@mui/material';
import { API_GetCandidates } from '../Assets/ApiUrlContainer';

const CandidatesListPage = () => {
	const [candidates, setCandidates] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [listDisplayStartingIndex, setListDisplayStartingIndex] = useState(0);
	const [listDisplayLimit, setListDisplayLimit] = useState(0);

	useEffect(() => {
		const fetchCandidates = async () => {
			try {
				const response = await axios.get(API_GetCandidates);
				setCandidates(response.data);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching candidates:', error);
				setLoading(false);
			}
		};

		fetchCandidates();
	}, []);

	const handlePageChange = (newPositionIndex) => setListDisplayStartingIndex(newPositionIndex);
	const handlePageListLimitChange = (newListDisplayLimit) => setListDisplayLimit(newListDisplayLimit);

	if (loading) {
		return <Typography>Loading...</Typography>;
	}

	return (
		<div className='tgs-content'>
			<Box sx={{ padding: '16px' }}>
				<Typography variant='h4' gutterBottom>
					{' '}
					Applicants{' '}
				</Typography>
				<TextField
					label='Search'
					variant='outlined'
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					sx={{ marginBottom: '16px', width: '600px', maxWidth: '100%', backgroundColor: '#f5f5f5' }}
				/>

				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Location</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{candidates
								.filter((candidate) => candidate.name.toLowerCase().includes(searchTerm.toLowerCase()))
								.slice(listDisplayStartingIndex, listDisplayLimit)
								.map((candidate) => (
									<TableRow key={candidate.email}>
										<TableCell>
											<Link to={`/applicants/${encodeURIComponent(candidate.email)}`}>
												{candidate.name}
											</Link>
										</TableCell>
										<TableCell>{candidate.email}</TableCell>
										<TableCell>{candidate.location || 'N/A'}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>

				<Pagination
					totalNumItems={candidates.length}
					onPageChangeNewPosition={handlePageChange}
					onRowsChangeNewDisplayLimit={handlePageListLimitChange}
				/>
			</Box>
		</div>
	);
};

export default CandidatesListPage;
