import React, { useState, useEffect, useRef } from 'react';
import { AppliedIcon, Interview1Icon, Interview2Icon, Interview3Icon } from './CustomIcons';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export const EllipsisStatusMenu = ({ openEllipsisMenu, onCloseEllipsis, onItemSelected, overrideStyle }) => {
	const ellipsisRef = useRef(null);

	const [lastHoverMenuItem, setLastHoverMenuItem] = useState('');
	const [openLabelMenu, setOpenLabelMenu] = useState(false);
	const [openStatusMenu, setOpenStatusMenu] = useState(false);
	const [mainMenuList, setMainMenuList] = useState([]);

	useEffect(() => {
		setMainMenuList([
			{
				menuItem: 'Status',
				menuList: ['Applied', 'Interview 1', 'Interview 2', 'Interview 3', 'Hired', 'Rejected'],
			},
			{
				menuItem: 'Label',
				menuList: ['Interviewed', 'Interview Scheduled'],
			},
		]);

		document.addEventListener('click', handleClickedOutsideEllipsisContainer, true);

		return () => {
			setLastHoverMenuItem('');
			setOpenLabelMenu(false);
			setOpenStatusMenu(false);
			document.removeEventListener('click', handleClickedOutsideEllipsisContainer, true);
		};
	}, [openEllipsisMenu, ellipsisRef.current]);

	const handleClickedOutsideEllipsisContainer = (event) => {
		if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
			setLastHoverMenuItem('');
			setOpenLabelMenu(false);
			setOpenStatusMenu(false);
			onCloseEllipsis();
		}
	};

	const ProgressCircle = (category) => {
		if (category === 'Hired') {
			return <CheckCircle sx={{ color: 'green' }} />;
		} else if (category === 'Rejected') {
			return <CancelIcon sx={{ color: 'red' }} />;
		} else if (category === 'Applied' || category === 'Matched') {
			return <AppliedIcon />;
		} else if (category === 'Interview 1') {
			return <Interview1Icon />;
		} else if (category === 'Interview 2') {
			return <Interview2Icon />;
		} else if (category === 'Interview 3') {
			return <Interview3Icon />;
		}
		return null;
	};

	const handleMouseOverMenuItem = (mainMenuItem) => {
		setLastHoverMenuItem(mainMenuItem.menuItem);
		setOpenStatusMenu(mainMenuItem.menuItem === mainMenuList[0].menuItem);
		setOpenLabelMenu(mainMenuItem.menuItem === mainMenuList[1].menuItem);
	};

	const handleItemSelected = (menuItem, listItem) => {
		// console.log({ menuItem, listItem });
		onItemSelected({ menuItem, listItem });
		setLastHoverMenuItem('');
		setOpenLabelMenu(false);
		setOpenStatusMenu(false);
	};

	return (
		<>
			{openEllipsisMenu && (
				<div className='ellipsisMenuContainer' ref={ellipsisRef} style={overrideStyle}>
					<div className='menuItems'>
						{mainMenuList.map((menuListItem, index) => (
							<div
								key={index}
								className='mainMenuItem'
								onMouseOver={() => handleMouseOverMenuItem(menuListItem)}
							>
								<span className='chevron-left-icon'>
									<FontAwesomeIcon icon={faChevronLeft} />
								</span>

								<div className='menuItemText'>
									{menuListItem.menuItem}

									{openStatusMenu && lastHoverMenuItem === menuListItem.menuItem && (
										<div className='statusList'>
											{menuListItem.menuList.map((statusListItem) => (
												<div
													key={statusListItem}
													onClick={() =>
														handleItemSelected(menuListItem.menuItem, statusListItem)
													}
												>
													<span className='menuItemIcon'>
														{ProgressCircle(statusListItem)}
													</span>

													<div className='statisItemText'>{statusListItem}</div>
												</div>
											))}
										</div>
									)}

									{openLabelMenu && lastHoverMenuItem === menuListItem.menuItem && (
										<div className='labelList'>
											{menuListItem.menuList.map((labelListItem) => (
												<div
													key={labelListItem}
													onClick={() =>
														handleItemSelected(menuListItem.menuItem, labelListItem)
													}
												>
													<span
														style={{
															width: '10px',
															height: '10px',
															borderRadius: '50%',
															backgroundColor:
																labelListItem === 'Interviewed' ? 'green' : 'orange',
															marginRight: '8px',
															marginTop: 'auto',
															marginBottom: 'auto',
														}}
													/>
													<div className='labelItemText'>{labelListItem}</div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};
