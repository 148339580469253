import { useContext, useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Avatar } from '@mui/material';
import UserContext from '../Contexts/UserContext';

const SettingsPage = () => {
	const currentUser = useContext(UserContext);
	const [user, setUser] = useState(null);

	useEffect(() => {
		setUser(currentUser.user);
	}, [currentUser, setUser]);

	return (
		<div className='tgs-content'>
			<div className='settingsPage'>
				{user && (
					<Box sx={{ padding: '16px' }}>
						<Typography variant='h6' gutterBottom>
							Settings
						</Typography>
						<Typography variant='body2' color='textSecondary' gutterBottom>
							Customize your profile image, personal information and change your password.
						</Typography>

						{/* Profile Image Section */}
						<Box sx={{ display: 'flex', alignItems: 'center', marginY: '16px' }}>
							{!user.AccountPictureUrl && <Avatar sx={{ width: 60, height: 60 }}></Avatar>}

							{user.AccountPictureUrl && (
								<img className='userPhoto' src={user.AccountPictureUrl} alt='Profile' />
							)}
							<Button variant='contained' sx={{ marginLeft: '16px' }}>
								Upload
							</Button>
						</Box>

						{/* Personal Information Section */}
						<Box sx={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
							<TextField label='First Name' defaultValue={user.Firstname || ''} fullWidth />
							<TextField label='Last Name' defaultValue={user.Lastname || ''} fullWidth />
						</Box>
						<TextField
							label='Email'
							defaultValue={user.Email}
							fullWidth
							disabled
							helperText='Contact admin to change your email address.'
						/>

						{/* Password Section */}
						<Box sx={{ marginTop: '16px' }}>
							<Button variant='contained'>Change Password</Button>
							<Typography variant='caption' display='block' sx={{ marginTop: '8px' }}>
								Last changed:
							</Typography>
						</Box>
					</Box>
				)}
			</div>
		</div>
	);
};

export default SettingsPage;
