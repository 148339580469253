import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Typography, Chip } from '@mui/material';
import { AppliedIcon, Interview1Icon, Interview2Icon, Interview3Icon } from './CustomIcons';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { EllipsisStatusMenu } from './EllipsisStatusContent';
import {
	API_GetApplicantDetails,
	API_GetApplicantJobs,
	API_UpdateCandidateDetails,
	API_UpdateCandidateInterviewRemarks,
	API_UpdateCandidateInterviewStage,
	API_UpdateCandidateRemarks,
} from '../Assets/ApiUrlContainer';

const ApplicantDetailsPage = () => {
	const { applicantId } = useParams();
	const [applicant, setApplicant] = useState(null);
	const [loading, setLoading] = useState(true);

	const [isEdittingApplicantDetails, setIsEdittingApplicantDetails] = useState(false);
	const [phone, setPhone] = useState('');
	const [summary, setSummary] = useState('');
	const [location, setLocation] = useState('');
	const [linkedIn, setLinkedIn] = useState('');
	const [website, setWebsite] = useState('');

	const [remarks, setRemarks] = useState(''); // General remarks
	const [editingRemarks, setEditingRemarks] = useState(false); // For general remarks
	const [remarksEntryText, setRemarksEntryText] = useState('');

	const [jobs, setJobs] = useState([]); // Holds job applications
	const [jobRemarksEntryText, setJobRemarksEntryText] = useState('');
	const [editingJobId, setEditingJobId] = useState(null); // Track which job is being edited
	const [openEllipsisMenuJobId, setOpenEllipsisMenuJobId] = useState(null);
	const [selectedJob, setSelectedJob] = useState(null); // For selected job

	const [showMoreToggled, setShowMoreToggled] = useState(false);

	useEffect(() => {
		fetchApplicantDetails();
		fetchApplicantJobs();
	}, [applicantId]);

	const fetchApplicantDetails = async () => {
		try {
			const response = await axios.get(`${API_GetApplicantDetails}/${applicantId}`);
			setApplicant(response.data);
			setRemarks(response.data.Remarks || '');
			setLoading(false);
		} catch (error) {
			console.error('Error fetching applicant details:', error);
			setLoading(false);
		}
	};

	const fetchApplicantJobs = async () => {
		try {
			const response = await axios.get(`${API_GetApplicantJobs}/${applicantId}`);
			setJobs(response.data);
		} catch (error) {
			console.error('Error fetching applicant jobs:', error);
		}
	};

	const handleEditApplicantDetails = () => {
		setIsEdittingApplicantDetails(!isEdittingApplicantDetails);
		setPhone(applicant.Phone || '');
		setSummary(applicant.Summary || '');
		setLocation(applicant.Location || '');
		setLinkedIn(applicant.LinkedIn || '');
		setWebsite(applicant.Website || '');
	};

	const handleCloseEditDetails = () => {
		setIsEdittingApplicantDetails(false);
		setPhone('');
		setSummary('');
		setLocation('');
		setLinkedIn('');
		setWebsite('');
	};

	const handleSaveEditDetails = async () => {
		try {
			await axios.put(API_UpdateCandidateDetails, {
				applicantId: applicant.ID,
				phone,
				summary,
				location,
				linkedIn,
				website,
			});

			fetchApplicantDetails();
			handleCloseEditDetails();
		} catch (error) {
			console.error(error);
		}
	};

	const handleSaveRemarks = async () => {
		try {
			let remarksResponse = await axios.put(API_UpdateCandidateRemarks, {
				applicantId: applicant.ID,
				remarks: remarksEntryText,
			});

			setRemarks(remarksResponse.data.remarks);
			setEditingRemarks(false);
		} catch (error) {
			console.error('Error saving remarks:', error);
			alert('Failed to save remarks. Please try again.');
		}
	};

	const handleSaveJobRemarks = async (job) => {
		await axios.put(API_UpdateCandidateInterviewRemarks, {
			interviewId: job.interviewId,
			notes: jobRemarksEntryText,
		});

		await fetchApplicantJobs();
		setJobRemarksEntryText('');
		setEditingJobId(null);
	};

	const handleEditJobRemarks = (job) => {
		setEditingJobId(job.jobId);
		setJobRemarksEntryText(job.remarks);
	};

	const handleCancelEditJobRemarks = () => {
		setEditingJobId(null);
		setJobRemarksEntryText('');
	};

	const handleEditRemarks = () => {
		setEditingRemarks(true);
		setRemarksEntryText(remarks);
	};

	const handleCancelEditRemarks = () => {
		setEditingRemarks(false);
		setRemarksEntryText('');
	};

	const handleOpenEllipsisMenuClicked = (job) => {
		setOpenEllipsisMenuJobId(job.jobId);
		setSelectedJob(job);
	};

	const handleCloseEllipsisMenu = () => {
		setOpenEllipsisMenuJobId(null);
		setSelectedJob(null);
	};

	const handleEllipsisOptionSelected = async (selectedEllipsisOption) => {
		let changeType = selectedEllipsisOption.menuItem;
		let newItem = selectedEllipsisOption.listItem;

		if (changeType === 'Status') {
			await handleStatusChange(newItem);
		} else {
			await handleLabelChange(newItem);
		}

		await fetchApplicantJobs();
		handleCloseEllipsisMenu();
	};

	const handleStatusChange = async (newState) => {
		if (selectedJob) {
			try {
				await axios.put(API_UpdateCandidateInterviewStage, {
					applicationId: selectedJob.applicationId,
					state: newState,
					label: selectedJob.label,
				});

				setJobs((prev) =>
					prev.map((job) =>
						job.applicationId === selectedJob.applicationId ? { ...job, state: newState } : job
					)
				);
			} catch (error) {
				console.error('Error updating status:', error);
				alert('Failed to update status. Please try again.');
			}
		}
	};

	const handleLabelChange = async (newLabel) => {
		if (selectedJob) {
			try {
				await axios.put(API_UpdateCandidateInterviewStage, {
					applicationId: selectedJob.applicationId,
					state: selectedJob.state,
					label: newLabel,
				});

				setJobs((prev) =>
					prev.map((job) =>
						job.applicationId === selectedJob.applicationId ? { ...job, label: newLabel } : job
					)
				);
			} catch (error) {
				console.error('Error updating label:', error);
				alert('Failed to update label. Please try again.');
			}
		}
	};

	const handleShowMoreSummaryToggled = () => {
		setShowMoreToggled(!showMoreToggled);
	};

	// Function to render the label chip
	const renderLabel = (label) => {
		// Do not render anything if there's no label
		if (!label) return null;

		let chipColor = ''; // Default empty
		let chipLabel = label || 'N/A'; // Default label if none

		// Determine the chip color based on label
		if (label === 'Interviewed') {
			chipColor = 'green';
		} else if (label === 'Interview Scheduled') {
			chipColor = 'orange';
		}

		return (
			<Chip
				label={chipLabel}
				style={{
					backgroundColor: 'transparent', // No background color for the chip
					color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
					fontFamily: 'var(--fontFamily, Roboto)', // Apply the font family
					fontSize: 'var(--font-size-08125-rem, 13.5px)', // Apply the font size
					fontWeight: 'var(--fontWeightRegular, 400)', // Apply the font weight
					lineHeight: '18px', // Set line height
					letterSpacing: '0.16px', // Set letter spacing
					padding: '4px 10px',
					borderColor: 'grey', // Grey border
					borderWidth: '1px',
					borderStyle: 'solid',
					height: '26px',
				}}
				icon={
					chipColor ? (
						<span
							style={{
								width: '10px',
								height: '10px',
								borderRadius: '50%',
								backgroundColor: chipColor, // Set color of the dot
								marginRight: '8px', // Space between dot and label text
							}}
						/>
					) : null
				}
			/>
		);
	};

	const ProgressCircle = (category) => {
		if (category === 'Hired') {
			return <CheckCircle sx={{ color: 'green' }} />;
		} else if (category === 'Rejected') {
			return <CancelIcon sx={{ color: 'red' }} />;
		} else if (category === 'Applied' || category === 'Matched') {
			return <AppliedIcon />;
		} else if (category === 'Interview 1') {
			return <Interview1Icon />;
		} else if (category === 'Interview 2') {
			return <Interview2Icon />;
		} else if (category === 'Interview 3') {
			return <Interview3Icon />;
		}
		return null;
	};

	if (loading) {
		return <Typography>Loading...</Typography>;
	}

	if (!applicant) {
		return <Typography>Applicant not found</Typography>;
	}

	return (
		<>
			<div className='candidateContainer'>
				<div className='tgs-content'>
					<div className='candidateProfileInfo'>
						<div className='candidateName'>
							{applicant.Name || 'No Name'}

							{!isEdittingApplicantDetails && (
								<span className='editJobRemarks' onClick={() => handleEditApplicantDetails()}>
									<FontAwesomeIcon icon={faEdit} />
								</span>
							)}
						</div>
						<div className='candidateContactDetails'>
							<div className='leftPanel'>
								<div className={`email ${isEdittingApplicantDetails ? 'unEditableBorder' : ''}`}>
									<div className='label'>Email</div>
									<div className='labelEntry'>{applicant.Email || '-'}</div>
								</div>

								<div className='phoneNumber'>
									<div className='label'>Phone Number</div>
									{!isEdittingApplicantDetails && (
										<div className='labelEntry'>{applicant.Phone || '-'}</div>
									)}
									{isEdittingApplicantDetails && (
										<textarea value={phone} onChange={(e) => setPhone(e.target.value)}></textarea>
									)}
								</div>

								<div className='summary'>
									<div className='label'>Summary</div>

									{!isEdittingApplicantDetails && (
										<div className={`labelEntry ${showMoreToggled ? 'expandedSummary' : ''}`}>
											{applicant.Summary || '-'}
										</div>
									)}
									{isEdittingApplicantDetails && (
										<textarea
											value={summary}
											onChange={(e) => setSummary(e.target.value)}
										></textarea>
									)}

									{!isEdittingApplicantDetails && applicant.Summary && (
										<div className='showMoreLessBtn'>
											<span onClick={handleShowMoreSummaryToggled}>
												{showMoreToggled ? 'Show less' : 'Show more'}
											</span>
										</div>
									)}
								</div>
							</div>

							<div className='rightPanel'>
								<div className='location'>
									<div className='label'>Location</div>

									{!isEdittingApplicantDetails && (
										<div className='labelEntry'>{applicant.Location || '-'}</div>
									)}
									{isEdittingApplicantDetails && (
										<textarea
											value={location}
											onChange={(e) => setLocation(e.target.value)}
										></textarea>
									)}
								</div>

								<div className='linkedIn'>
									<div className='label'>LinkedIn Profile</div>
									{!isEdittingApplicantDetails && (
										<div className='labelEntry'>
											{applicant.LinkedIn ? (
												<a href={applicant.LinkedIn} target='_blank'>
													{applicant.LinkedIn}
												</a>
											) : (
												'-'
											)}
										</div>
									)}
									{isEdittingApplicantDetails && (
										<textarea
											value={linkedIn}
											onChange={(e) => setLinkedIn(e.target.value)}
										></textarea>
									)}
								</div>

								<div className='website'>
									<div className='label'>Website</div>
									{!isEdittingApplicantDetails && (
										<div className='labelEntry'>
											{applicant.Website ? (
												<a href={applicant.Website} target='_blank'>
													{applicant.Website}
												</a>
											) : (
												'-'
											)}
										</div>
									)}
									{isEdittingApplicantDetails && (
										<textarea
											value={website}
											onChange={(e) => setWebsite(e.target.value)}
										></textarea>
									)}
								</div>
							</div>
						</div>

						{isEdittingApplicantDetails && (
							<div className='applicantEditDetailsButtons'>
								<button className='cancelDetailsEditBtn' onClick={handleCloseEditDetails}>
									CANCEL
								</button>

								<button className='saveDetailsBtn' onClick={handleSaveEditDetails}>
									SAVE
								</button>
							</div>
						)}
					</div>
				</div>

				<div className='tgs-content'>
					<div className='candidateResumeDownload'>
						<div className='resumeDownloadHeading'>Resume</div>
						<div className='resumeDownloadFile'>
							<div className='fileName'>
								{applicant.Resume ? (
									<a href={applicant.Resume} target='_blank' rel='noopener noreferrer'>
										{applicant.Name ? `Resume_${applicant.Name}.pdf` : 'Resume.pdf'}
									</a>
								) : (
									<div className='No resume available'></div>
								)}
							</div>

							{applicant.Resume && (
								<div className='downloadIcon' onClick={() => window.open(applicant.Resume, '_blank')}>
									<FontAwesomeIcon icon={faDownload} />
								</div>
							)}
						</div>
					</div>
				</div>

				<div className='tgs-content'>
					<div className='candidateRemarks'>
						<div className='remarksHeading'>
							<div className='heading'>
								Remarks
								{editingRemarks && (
									<div className='instructionText'>Provide a general remark or leave a comment</div>
								)}
							</div>

							{!editingRemarks && remarks.length > 0 && (
								<span onClick={handleEditRemarks}>
									<FontAwesomeIcon icon={faEdit} />
								</span>
							)}
						</div>

						{!editingRemarks && remarks.length > 0 && <div className='remarksViewContent'>{remarks}</div>}
						{!editingRemarks && remarks.length === 0 && (
							<div className='addRemarksBtnContainer'>
								<button className='addRemarksBtn' onClick={handleEditRemarks}>
									ADD REMARK
								</button>
							</div>
						)}

						{editingRemarks && (
							<div className='remarksEditContent'>
								<textarea
									value={remarksEntryText}
									onChange={(e) => setRemarksEntryText(e.target.value)}
								></textarea>

								<div className='remarksEditButtons'>
									<button className='cancelRemarksEditBtn' onClick={handleCancelEditRemarks}>
										CANCEL
									</button>

									<button className='saveRemarksBtn' onClick={handleSaveRemarks}>
										SAVE
									</button>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className='tgs-content'>
					<div className='candidateApplications'>
						<div className='applicationsHeading'>Job Applications</div>
						{jobs.length === 0 && <div className='applicationDetails'>No job applications applicable</div>}
						{jobs.length > 0 &&
							jobs.map((job, index) => (
								<div className='applicationDetails' key={index}>
									<div className='jobApplicationLink'>
										<Link to={`/jobs/${job.jobId}`}>{job.name}</Link>
									</div>

									<div className='jobApplicationStatus'>
										<div className='icon'>{ProgressCircle(job.state)}</div>
										<div className='iconStatusText'>{job.state}</div>
										{job.label && <div className='chipColor'>{renderLabel(job.label)}</div>}
										<span
											className='editJobState'
											onClick={() => handleOpenEllipsisMenuClicked(job)}
										>
											<FontAwesomeIcon icon={faEllipsisV} />
											<EllipsisStatusMenu
												openEllipsisMenu={openEllipsisMenuJobId === job.jobId}
												onCloseEllipsis={handleCloseEllipsisMenu}
												onItemSelected={handleEllipsisOptionSelected}
											/>
										</span>
									</div>

									{editingJobId === job.jobId && (
										<div className='instructionText'>
											Provide a general remark or leave a comment for the application interviews
										</div>
									)}

									{editingJobId !== job.jobId && job.interviewId && (
										<div className='jobRemarks'>
											{job.interviewId && job.remarks.length > 0 && (
												<div className='label'>Interview Notes</div>
											)}
											<div className='labelEntry'>
												{job.remarks.length > 0 && (
													<div className='jobRemarksText'>{job.remarks}</div>
												)}
												{job.interviewId && job.remarks.length > 0 && (
													<span
														className='editJobRemarks'
														onClick={() => handleEditJobRemarks(job)}
													>
														<FontAwesomeIcon icon={faEdit} />
													</span>
												)}

												{job.interviewId && job.remarks.length === 0 && (
													<div className='addRemarksBtnContainer'>
														<button
															className='addRemarksBtn'
															onClick={() => handleEditJobRemarks(job)}
														>
															ADD INTERVIEW REMARK
														</button>
													</div>
												)}
											</div>
										</div>
									)}

									{editingJobId === job.jobId && (
										<div className='jobRemarks'>
											<textarea
												value={jobRemarksEntryText}
												onChange={(e) => setJobRemarksEntryText(e.target.value)}
											></textarea>

											<div className='remarksEditButtons'>
												<button
													className='cancelRemarksEditBtn'
													onClick={handleCancelEditJobRemarks}
												>
													CANCEL
												</button>

												<button
													className='saveRemarksBtn'
													onClick={() => handleSaveJobRemarks(job)}
												>
													SAVE
												</button>
											</div>
										</div>
									)}
								</div>
							))}
					</div>
				</div>
			</div>
		</>
	);
};

export default ApplicantDetailsPage;
