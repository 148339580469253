/* global google */
import './App.scss';
import { useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import LoginPage from './Components/LoginPage';
import { TGSHeader } from './Components/TGSHeader.js';
import Sidebar from './Components/Sidebar';
import SettingsSidebar from './Components/SettingsSidebar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import JobsPage from './Components/JobsPage';
import JobDetailsPage from './Components/JobDetailsPage';
import SettingsPage from './Components/SettingsPage';
import ApplicantDetailsPage from './Components/ApplicantDetailsPage';
import CandidatesListPage from './Components/CandidatesListPage';
import UserManagementPage from './Components/UserManagementPage';
import { Personas } from './Components/Personas.js';
import { API_GoogleUserInfo } from './Assets/ApiUrlContainer.js';
import UserContext from './Contexts/UserContext.js';
import { GoogleOAuthProvider, googleLogout } from '@react-oauth/google';

function App() {
	const [user, setUser] = useState(null);
	const [role, setRole] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);

	const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	const handleGoogleLoginSuccess = async (token) => {
		try {
			const accountToken = jwtDecode(token);
			const email = accountToken.email;
			const picture = accountToken.picture;
			let firstname = accountToken.name.split(' ')[0] || '';
			let lastname = accountToken.name.split(' ')[1] || '';

			if (firstname) {
				firstname = firstname[0].toUpperCase() + firstname.slice(1).toLowerCase();
			}

			if (lastname) {
				lastname = lastname[0].toUpperCase() + lastname.slice(1).toLowerCase();
			}

			const response = await axios.post(API_GoogleUserInfo, { email, firstname, lastname, picture });
			const googleUser = response.data.user;
			const googleUserRole = response.data.userRole;

			setUser(googleUser);
			setRole(googleUserRole);
			setCurrentUser({ user: googleUser, role: googleUserRole });
		} catch (error) {
			console.error(error);
		}
	};

	const handleLogout = () => {
		googleLogout();
		setUser(null);
	};

	return (
		<GoogleOAuthProvider clientId={clientId}>
			<UserContext.Provider value={currentUser}>
				{!user && (
					<>
						<TGSHeader />
						<LoginPage onGoogleLoginSuccess={handleGoogleLoginSuccess} />
					</>
				)}

				{user && (
					<Router>
						<Routes>
							{/* Layout with Global Sidebar */}
							<Route
								path='/*'
								element={
									<div className='tgs-content-container'>
										<Sidebar handleLogout={handleLogout} />
										<div className='tgs-content-background'>
											<Routes>
												{/* Define specific paths under this layout */}
												<Route path='jobs' element={<JobsPage />} />
												<Route path='jobs/:id' element={<JobDetailsPage />} />
												<Route path='candidates' element={<CandidatesListPage />} />
												<Route
													path='applicants/:applicantId'
													element={<ApplicantDetailsPage />}
												/>
												<Route path='personas' element={<Personas />} />
												<Route
													index
													element={
														<div className='tgs-content'>
															<h1
																style={{
																	height: 'calc(100vh - 43px - 2rem)',
																	padding: '1rem',
																}}
															>
																Welcome {`${user.Firstname} ${user.Lastname || ''}`}
															</h1>
														</div>
													}
												/>
											</Routes>
										</div>
									</div>
								}
							/>

							{/* Layout with Settings Sidebar */}
							<Route
								path='/settings/*'
								element={
									<div style={{ display: 'flex' }}>
										<SettingsSidebar isAdmin={role.role === 'admin'} />
										<div style={{ padding: '20px', width: '100%' }}>
											<Routes>
												<Route path='/' element={<SettingsPage />} />
												<Route
													path='user-management'
													element={<UserManagementPage role={role} />}
												/>
											</Routes>
										</div>
									</div>
								}
							/>
						</Routes>
					</Router>
				)}
			</UserContext.Provider>
		</GoogleOAuthProvider>
	);
}

export default App;
