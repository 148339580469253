// Base api constants
const API_Protocol = `http://`;
const API_Location = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`;
const API_Url = `${API_Protocol}${API_Location}`;

// Google user api constants
export const API_GoogleUserInfo = `${API_Url}/get-google-user`;

// Candidates api constants
export const API_GetCandidates = `${API_Url}/get-candidates`;

// Applications api constants
export const API_GetApplicantDetails = `${API_Url}/applicant-details`;
export const API_GetApplicantJobs = `${API_Url}/applicant-jobs`;
export const API_UpdateCandidateRemarks = `${API_Url}/update-remarks`;
export const API_UpdateCandidateInterviewRemarks = `${API_Url}/update-interview-remarks`;
export const API_UpdateCandidateInterviewStage = `${API_Url}/update-interview-stage`;
export const API_GetJobApplications = `${API_Url}/show-applications`;
export const API_CreateJobApplication = `${API_Url}/create-job-application`;
export const API_UpdateCandidateDetails = `${API_Url}/update-candidate-details`;

// Jobs api constants
export const API_GetJob = `${API_Url}/get-job`;
export const API_GetJobs = `${API_Url}/get-jobs`;
export const API_UpdateJobStatus = `${API_Url}/update-job-status`;

// Persona api constants
export const API_GetJobPersonaDetails = `${API_Url}/get-persona-and-skills`;
export const API_UpdateJobPersona = `${API_Url}/update-job-persona`;
export const API_UpdatePersonaSkills = `${API_Url}/update-persona-skills`;
export const API_GetAllPersonaContent = `${API_Url}/get-all-personas-content`;
export const API_CreatePersona = `${API_Url}/add-persona`;
export const API_UpdatePersona = `${API_Url}/update-persona`;

// Employee api constant
export const API_GetEmployees = `${API_Url}/get-employees`;
