import { useState } from 'react';
import {
	FormHelperText,
	Typography,
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
	Box,
	CircularProgress,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleLogin } from '@react-oauth/google';

const LoginPage = ({ onGoogleLoginSuccess }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);

	const [recaptchaToken, setRecaptchaToken] = useState(null); // Store reCAPTCHA token
	const [captchaError, setCaptchaError] = useState(false);

	// Google OAuth setup
	const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

	const handleSubmit = (event) => {
		event.preventDefault();

		// Basic validation logic
		const emailValid = email.length > 0 && email.includes('@');
		const passwordValid = password.length > 0;

		if (!emailValid) {
			setEmailError(true); // Set error state for email
		}

		if (!passwordValid) {
			setPasswordError(true); // Set error state for password
		}

		if (!recaptchaToken) {
			setCaptchaError(true);
			alert('Please complete the CAPTCHA');
			return;
		}

		// If both fields are valid, proceed with login
		if (emailValid && passwordValid && recaptchaToken) {
			setLoading(true); // Show loading indicator and disable fields during processing
			setEmailError(false);
			setPasswordError(false);
			setCaptchaError(false);

			// Simulate a network request
			setTimeout(() => {
				setLoading(false); // Hide loading after request finishes
				console.log('CAPTCHA passed, but not verified with backend');
			}, 2000);
		}
	};

	// reCAPTCHA onChange handler
	const handleRecaptchaChange = (token) => {
		setRecaptchaToken(token); // Store the token
		if (token) {
			setCaptchaError(false); // Clear the error once CAPTCHA is completed
		}
	};

	return (
		<Box
			className='loginContainer'
			sx={{
				height: '100vh',
				width: '100vw',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start', // Start closer to the top
				alignItems: 'center',
				backgroundColor: '#FAFAFA',
				paddingTop: '32px', // Adjust top padding to bring card closer to the top
			}}
		>
			<Box sx={{ maxWidth: '580px', width: '100%', textAlign: 'center' }}>
				{' '}
				{/* Remove Card */}
				{/* Welcome Text */}
				<Typography
					variant='h4'
					component='h2'
					align='left'
					mb={1}
					sx={{
						fontFamily: 'var(--fontFamily)',
						fontSize: '2.125rem',
						fontWeight: 400,
						color: '#000000DE',
						textAlign: 'left',
					}}
				>
					Welcome
				</Typography>
				{/* Form Fields */}
				<form onSubmit={handleSubmit} noValidate>
					<TextField
						label='Email'
						variant='filled'
						size='medium'
						margin='normal'
						required
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
							setEmailError(false);
						}}
						error={emailError}
						disabled={loading}
						fullWidth
						sx={{ marginBottom: '16px' }}
					/>
					{emailError && <FormHelperText error>Enter a valid email address</FormHelperText>}
					<TextField
						label='Password'
						variant='filled'
						size='medium'
						margin='normal'
						required
						type={showPassword ? 'text' : 'password'}
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
							setPasswordError(false);
						}}
						error={passwordError}
						disabled={loading}
						fullWidth
						sx={{ marginBottom: '16px' }}
					/>
					{passwordError && <FormHelperText error>Enter a valid password</FormHelperText>}
					<FormControlLabel
						control={
							<Checkbox
								size='medium'
								color='primary'
								checked={showPassword}
								onChange={() => setShowPassword(!showPassword)}
							/>
						}
						label='Show password'
						sx={{ width: '100%', marginBottom: '16px', justifyContent: 'left' }}
					/>
					{/* Google reCAPTCHA */}
					<Box mt={0} mb={2}>
						<ReCAPTCHA sitekey={siteKey} onChange={handleRecaptchaChange} />
						{captchaError && (
							<FormHelperText error sx={{ marginLeft: 0 }}>
								Please complete the CAPTCHA
							</FormHelperText>
						)}
					</Box>
					{/* Main Log In Button */}
					<Button
						fullWidth
						variant='contained'
						color='primary'
						size='large'
						type='submit'
						disabled={loading}
						sx={{
							height: '42px',
							padding: '8px 22px',
							borderRadius: '4px',
							backgroundColor: '#673AB7',
							'&:hover': { backgroundColor: '#5e35b1' },
							'&.Mui-disabled': { backgroundColor: '#0000001F' },
						}}
					>
						{loading ? <CircularProgress size={24} color='inherit' /> : 'LOG IN'}
					</Button>
					{/* Divider Text */}
					<Typography sx={{ marginY: '16px', color: '#00000099', fontSize: '14px' }}>OR</Typography>
					<div className='googleLoginBtn'>
						<GoogleLogin
							useOneTap
							theme='filled_blue'
							width='400'
							onSuccess={(credentialResponse) => {
								onGoogleLoginSuccess(credentialResponse.credential);
							}}
						/>
					</div>
				</form>
			</Box>

			{/* Footer Section */}
			<Box
				component='footer'
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '16px',
					backgroundColor: '#FAFAFA',
					width: '100%',
					position: 'fixed',
					bottom: 0,
					fontSize: '14px',
					color: 'rgba(0, 0, 0, 0.6)',
				}}
			>
				<Typography component='span' sx={{ mx: 1 }}>
					© 2024 The Growth Spark
				</Typography>
				<Typography component='span' sx={{ mx: 1 }}>
					Request Access
				</Typography>
				<Typography component='span' sx={{ mx: 1 }}>
					Lorem Ipsum
				</Typography>
				<Typography component='span' sx={{ mx: 1 }}>
					Dolor Si Amet
				</Typography>
			</Box>
		</Box>
	);
};

export default LoginPage;
