import { TablePagination } from "@mui/material";
import React, { useState, useEffect } from "react";

export const Pagination = ({ totalNumItems, onPageChangeNewPosition, onRowsChangeNewDisplayLimit }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [listStartingIndex, setListStartingIndex] = useState(0);

    useEffect(() => {
        onPageChangeNewPosition(page * rowsPerPage)
        onRowsChangeNewDisplayLimit((page * rowsPerPage) + (Math.min(rowsPerPage, totalNumItems)));
    }, [page, rowsPerPage, totalNumItems, onPageChangeNewPosition, onRowsChangeNewDisplayLimit])

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
        onPageChangeNewPosition(newPage * rowsPerPage);
        setListStartingIndex(newPage * rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        let rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(rowsPerPage);
        setPage(0);

        let recordsOnPage = Math.min(rowsPerPage, totalNumItems);
        let pageDisplayLimitIndex = listStartingIndex + recordsOnPage;
        onRowsChangeNewDisplayLimit(pageDisplayLimitIndex);
    };

    return (
        <TablePagination 
            component="div"
            count={totalNumItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )
};