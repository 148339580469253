import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";

export const ModalEditPersona = ({ persona, allRoles, allIndustries, allSkills, onModalCancel, onModalSave, isAddPersona }) => {
    const [personaDetails, setPersonaDetails] = useState(null);
    const [availableSkills, setAvailableSkills] = useState([]);
    const [selectedRoleSkills, setSelectedRoleSkills] = useState([]);
    const [skillEntryText, setSkillEntryText] = useState("");
    const [skillsEntered, setSkillsEntered] = useState([]);
    const [modalHasErrors, setModalHasErrors] = useState(false);
    const [addPersonaCanSave, setAddPersonaCanSave] = useState(false);
    const [errorStates, setErrorStates] = useState({
        name: false,
        role: false,
        industry: false,
        skillsList: false
    });
    
    useEffect(() => {   
        setPersonaDetails(persona);
        setSkillsEntered(persona.RelevantSkills.length > 0 ? persona.RelevantSkills.split(", ") : []);
        setSelectedRoleSkills(allSkills.filter(skill => skill.JobType_ID === persona.RoleID));
        setAvailableSkills(allSkills.filter(skill => skill.JobType_ID === persona.RoleID && !persona.RelevantSkills.includes(skill.Name)));
    }, [persona, allSkills, setPersonaDetails, setAvailableSkills, setSkillsEntered, setSelectedRoleSkills])

    const handleUpdateErrorStates = (target, updatedValue) => {
        let updatedErrorStates = {
            ...errorStates,
            [target]: updatedValue.length === 0
        }
        
        setErrorStates(updatedErrorStates)
        setModalHasErrors(
            updatedErrorStates.name ||
            updatedErrorStates.role ||
            updatedErrorStates.industry ||
            updatedErrorStates.skillsList
        );
    };

    const checkCanAddPersona = (persona) => {
        setAddPersonaCanSave(
            persona.Name.length > 0 &&
            persona.RoleName.length > 0 &&
            persona.IndustryName.length > 0 &&
            persona.RelevantSkills.length > 0
        )
    };

    const handlePersonaNameInput = (event) => {
        let personaName = event.target.value;
        let updatedPersona = {
            ...personaDetails,
            Name: personaName
        };

        setPersonaDetails(updatedPersona);
        checkCanAddPersona(updatedPersona);
        handleUpdateErrorStates("name", personaName);
    }

    const handlePersonaRoleEdit = (event) => {
        let roleName = event.target.value;
        let updatedPersona = {
            ...personaDetails,
            RoleName: roleName,
            RelevantSkills: ""
        };

        setPersonaDetails(updatedPersona);
        setSkillsEntered([]);

        let roleMatch = allRoles.find(role => role.Name === roleName);
        if (roleMatch) {
            setSelectedRoleSkills(allSkills.filter(skill => skill.JobType_ID === roleMatch.ID));
            setAvailableSkills(allSkills.filter(skill => skill.JobType_ID === roleMatch.ID));
        }

        checkCanAddPersona(updatedPersona);
        handleUpdateErrorStates("role", roleName);
    }

    const handlePersonaIndustryEdit = (event) => {
        let updatedPersona = {
            ...personaDetails,
            IndustryName: event.target.value
        };

        setPersonaDetails(updatedPersona);
        checkCanAddPersona(updatedPersona);
        handleUpdateErrorStates("industry", event.target.value);
    }

    const handleSkillTextEntry = (event) => {
        setSkillEntryText(event.target.value);
    };

    const addSkillToList = () => {
        let skills = personaDetails.RelevantSkills.length > 0 ? personaDetails.RelevantSkills.split(", ") : [];
        if (!skills.some(skill => skill.toLowerCase() === skillEntryText.toLowerCase())) {
            skills.push(skillEntryText.trim());
            
            setSkillsEntered(skills);
            setAvailableSkills(selectedRoleSkills.filter(skill => !skills.join(", ").includes(skill.Name)));
  
            let updatedPersona = {
                ...personaDetails,
                RelevantSkills: skills.join(", ")
            };

            setPersonaDetails(updatedPersona);        
            checkCanAddPersona(updatedPersona)
        }

        handleUpdateErrorStates("skillsList", skills.join(", "));
    }

    const removeSkillFromList = (skillName) => {
        let skills = personaDetails.RelevantSkills.split(", ");
        let indexSkill = skills.findIndex(skill => skill.toLowerCase() === skillName.toLowerCase());

        let newSkillsList = [
            ...skills.slice(0, indexSkill),
            ...skills.slice(indexSkill + 1, skills.length)
        ];
        
        setSkillsEntered(newSkillsList);
        setAvailableSkills(selectedRoleSkills.filter(skill => !newSkillsList.join(", ").includes(skill.Name)));
        
        let updatedPersona = {
            ...personaDetails,
            RelevantSkills: newSkillsList.join(", ")
        };

        setPersonaDetails(updatedPersona);
        checkCanAddPersona(updatedPersona)
        handleUpdateErrorStates("skillsList", newSkillsList.join(", "));
    }

    const handleSkillEntryEnterKey = (event) => {
        if (event.key === 'Enter') {
            addSkillToList();
            setSkillEntryText("");
        }
    };

    return (
        <>
            {personaDetails && (
                <div className="modalEditPersona">
                    {isAddPersona && (
                        <div className="personaName">
                            <span>Name</span>
                            <input type="text" className={`${errorStates.name ? "inputError" : ""}`} value={personaDetails.Name} onInput={handlePersonaNameInput} />
                            {errorStates.name && <div className="inputErrorMsg">This field is required</div>}
                        </div>
                    )}

                    <div className="personaRole">
                        <span>Role</span>
                        <input type="text" list="rolesList" className={`${errorStates.role ? "inputError" : ""}`} value={personaDetails.RoleName} onInput={handlePersonaRoleEdit} />
                        <datalist id="rolesList">
                            {allRoles.map(role => (
                                <option key={role.ID}>{role.Name}</option>
                            ))}
                        </datalist>
                        {errorStates.role && <div className="inputErrorMsg">This field is required</div>}
                    </div>

                    <div className="personaIndustry">
                        <span>Industry</span>
                        <input type="text" list="industryList" className={`${errorStates.industry ? "inputError" : ""}`} value={personaDetails.IndustryName} onInput={handlePersonaIndustryEdit} />
                        <datalist id="industryList">
                            {allIndustries.map(industry => (
                                <option key={industry.ID}>{industry.Name}</option>
                            ))}
                        </datalist>
                        {errorStates.industry && <div className="inputErrorMsg">This field is required</div>}
                    </div>

                    <div className="personaSkills">
                        <span>Skills</span>
                        <div className="skillEntry">
                            {skillsEntered.map(skill => (
                                <div className="skillEntryBubble" key={skill}>
                                    <span>{skill}</span>
                                    <FontAwesomeIcon icon={faCircleXmark} onClick={() => removeSkillFromList(skill)}/> 
                                </div>  
                            ))}
                        </div>
                        <input type="text" list="skillsList" className={`${errorStates.skillsList ? "inputError" : ""}`} value={skillEntryText} onInput={handleSkillTextEntry} onKeyDown={handleSkillEntryEnterKey} />
                        <datalist id="skillsList">
                            {availableSkills.map(skill => (
                                <option key={skill.ID}>{skill.Name}</option>
                            ))}
                        </datalist>
                        {errorStates.skillsList && <div className="inputErrorMsg">This field is required</div>}
                    </div>

                    <div className="modalBtns">
                        <button className="cancelBtn" onClick={onModalCancel}>CANCEL</button>
                        <button className="saveBtn" onClick={() => onModalSave(personaDetails)} disabled={isAddPersona ? !addPersonaCanSave : modalHasErrors}>{ isAddPersona ? "ADD" : "SAVE"}</button>
                    </div>
                </div>
            )}
        </>        
    )
}