import { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ModalPrompt } from './ModalPrompt';
import { ModalEditPersona } from './ModalEditPersona';
import { ModalViewPersona } from './ModalViewPersona';
import { Pagination } from './Pagination';
import { API_CreatePersona, API_GetAllPersonaContent, API_UpdatePersona } from '../Assets/ApiUrlContainer';

export const Personas = () => {
	const [searchText, setSearchText] = useState('');
	const [personaEntries, setPersonaEntries] = useState([]);
	const [filteredPersonas, setFilteredPersonas] = useState([]);
	const [industries, setIndustries] = useState([]);
	const [jobTypes, setJobTypes] = useState([]);
	const [skillsList, setSkillsList] = useState([]);

	const [modalToggleState, setModalToggleState] = useState(false);
	const [modalPersona, setModalPersona] = useState(null);
	const [unEdittedModalPersona, setUnedittedModalPersona] = useState(null);
	const [modalEditToggleState, setModalEditToggleState] = useState(false);
	const [modalAddToggleState, setModalAddToggleState] = useState(false);

	const [listDisplayStartingIndex, setListDisplayStartingIndex] = useState(0);
	const [listDisplayLimit, setListDisplayLimit] = useState(0);

	useEffect(() => {
		try {
			fetchPersonaContent();
		} catch (error) {
			console.error('Error fetching jobs:', error);
		}
	}, []);

	const fetchPersonaContent = async () => {
		const response = await axios.get(API_GetAllPersonaContent);

		setPersonaEntries(sortPersonas(response.data.personas));
		setFilteredPersonas(sortPersonas(response.data.personas));

		setIndustries(response.data.industries);
		setJobTypes(response.data.jobTypes);
		setSkillsList(response.data.skillsList);
	};

	const sortPersonas = (personas) =>
		[...personas].sort((a, b) => {
			const nameA = a.Name.toUpperCase();
			const nameB = b.Name.toUpperCase();
			if (nameA < nameB) return -1;
			if (nameA > nameB) return 1;
			return 0;
		});

	const handleSearchFilter = (event) => {
		setSearchText(event.target.value);
		setFilteredPersonas(
			sortPersonas(
				personaEntries.filter(
					(persona) =>
						persona.Name.toLowerCase().includes(event.target.value.toLowerCase()) ||
						persona.RoleName.toLowerCase().includes(event.target.value.toLowerCase()) ||
						persona.IndustryName.toLowerCase().includes(event.target.value.toLowerCase()) ||
						getDateString(persona.LastUpdated).toLowerCase().includes(event.target.value.toLowerCase())
				)
			)
		);
	};

	const getDateString = (dateString) => {
		let updatedDate = new Date(dateString);
		let month = updatedDate.toLocaleString('default', { month: 'short' });
		let day = updatedDate.getDate();
		let year = updatedDate.getFullYear();
		return `${month} ${day}, ${year}`;
	};

	const handleAddPersona = () => {
		setModalAddToggleState(!modalAddToggleState);
		setModalPersona({
			ID: 0,
			Name: '',
			YearsExperience: 0,
			LastUpdated: '',
			IndustryName: '',
			IndustryID: 0,
			RoleName: '',
			RoleID: '',
			RelevantSkills: '',
		});
		setModalToggleState(!modalToggleState);
	};

	const handleEditPersona = (persona) => {
		setModalPersona(persona);
		setUnedittedModalPersona(persona);
		setModalToggleState(!modalToggleState);
	};

	const handleModalClose = () => {
		setModalPersona(null);
		setUnedittedModalPersona(null);
		setModalToggleState(false);
		setModalAddToggleState(false);
		setModalEditToggleState(false);
	};

	const handleModalEditToggled = () => {
		setModalEditToggleState(!modalEditToggleState);
		if (!modalEditToggleState) {
			setModalPersona(unEdittedModalPersona);
		}
	};

	const handleModalSave = async (persona) => {
		let oldPersonaSkills = modalAddToggleState ? [] : modalPersona.RelevantSkills.split(', ');
		let newPersonaSkills = persona.RelevantSkills.split(', ');

		let oldSkillsToRemove = [];
		let newSkillsToAssign = [];
		let newSkillsToCreate = [];

		oldPersonaSkills.forEach((oldSkill) => {
			if (!newPersonaSkills.includes(oldSkill)) {
				oldSkillsToRemove.push({
					Persona_ID: persona.ID,
					Skill_ID: skillsList.find((skill) => skill.Name.toLowerCase() === oldSkill.toLowerCase()).ID,
				});
			}
		});

		newPersonaSkills.forEach((newSkill) => {
			if (!oldPersonaSkills.includes(newSkill)) {
				let jobType = jobTypes.find((jobType) => jobType.Name === persona.RoleName);

				if (jobType) {
					let skill = skillsList.find((skill) => skill.Name === newSkill && skill.JobType_ID === jobType.ID);

					if (skill) {
						newSkillsToAssign.push({ Persona_ID: persona.ID, Skill_ID: skill.ID });
					} else {
						newSkillsToCreate.push(newSkill);
					}
				} else {
					newSkillsToCreate.push(newSkill);
				}
			}
		});

		let currentDate = new Date();
		let formattedDate = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${(
			'0' + currentDate.getDate()
		).slice(-2)}`;

		let personaChanges = {
			ID: persona.ID,
			Name: persona.Name,
			IndustryName: persona.IndustryName,
			IsNew_IndustryName: !industries.some((industry) => industry.Name === persona.IndustryName),
			RoleName: persona.RoleName,
			IsNew_RoleName: !jobTypes.some((jobType) => jobType.Name === persona.RoleName),
			SkillsToRemove: oldSkillsToRemove,
			SkillsToAssign: newSkillsToAssign,
			SkillsToCreate: newSkillsToCreate,
			LastUpdated: formattedDate,
		};

		let response = null;
		if (modalAddToggleState) {
			response = await axios.post(API_CreatePersona, personaChanges);
		} else {
			response = await axios.put(API_UpdatePersona, personaChanges);
		}

		if (response) {
			setPersonaEntries(sortPersonas(response.data.personas));
			setFilteredPersonas(sortPersonas(response.data.personas));
			setIndustries(response.data.industries);
			setJobTypes(response.data.jobTypes);
			setSkillsList(response.data.skillsList);
		}

		handleModalClose();
	};

	const handlePageChange = (newPositionIndex) => setListDisplayStartingIndex(newPositionIndex);
	const handlePageListLimitChange = (newListDisplayLimit) => setListDisplayLimit(newListDisplayLimit);

	return (
		<>
			<div className='tgs-content'>
				<div className='personasContainer'>
					<div className='header'>
						<h4>Personas</h4>
					</div>

					<div className='personasContent'>
						<div className='searchBarContainer'>
							<div className='searchBar'>
								<span>
									<FontAwesomeIcon icon={faSearch} />
								</span>
								<input
									type='text'
									value={searchText}
									onInput={handleSearchFilter}
									placeholder='Search'
								/>
							</div>

							<div className='addPersonaBtnContainer'>
								<button onClick={handleAddPersona}>Add Persona</button>
							</div>
						</div>

						<div className='personaTable'>
							<div className='personaHeadings'>
								<div className='name'>Persona Name</div>
								<div className='role'>Role</div>
								<div className='industry'>Industry</div>
								<div className='lastUpdated'>Last Updated</div>
							</div>

							{filteredPersonas.slice(listDisplayStartingIndex, listDisplayLimit).map((persona) => (
								<div
									key={persona.ID}
									className='personaEntries'
									onClick={() => handleEditPersona(persona)}
								>
									<div className='name'>{persona.Name}</div>
									<div className='role'>{persona.RoleName}</div>
									<div className='industry'>{persona.IndustryName}</div>
									<div className='lastUpdated'>{getDateString(persona.LastUpdated)}</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<Pagination
					totalNumItems={filteredPersonas.length}
					onPageChangeNewPosition={handlePageChange}
					onRowsChangeNewDisplayLimit={handlePageListLimitChange}
				/>
			</div>

			{modalPersona && (
				<ModalPrompt
					onModalClose={handleModalClose}
					modalToggleState={modalToggleState}
					modalTitle={
						modalEditToggleState ? 'Edit Persona' : modalAddToggleState ? 'Add Persona' : modalPersona.Name
					}
					modalContent={
						modalEditToggleState || modalAddToggleState ? (
							<ModalEditPersona
								persona={modalPersona}
								allRoles={jobTypes}
								allIndustries={industries}
								allSkills={skillsList}
								onModalCancel={modalAddToggleState ? handleModalClose : handleModalEditToggled}
								onModalSave={handleModalSave}
								isAddPersona={modalAddToggleState}
							/>
						) : (
							<ModalViewPersona
								persona={modalPersona}
								onModalEditClick={handleModalEditToggled}
								onModalClose={handleModalClose}
							/>
						)
					}
				/>
			)}
		</>
	);
};
