import TGSLogo from '../Assets/tgs-logo.svg';
import { Typography, Box } from '@mui/material';

export const TGSHeader = () => {
	return (
		<Box
			display='flex'
			justifyContent='center'
			alignItems='center'
			sx={{
				width: '100%',
				height: '96px', // Fixed height
				display: 'flex', // Flexbox for alignment
				justifyContent: 'center', // Center horizontally
				alignItems: 'center', // Center vertically
				borderBottom: '1px solid rgba(0, 0, 0, 0.08)', // Bottom border with 8% opacity
			}}
		>
			{/* TGS Logo */}
			<img src={TGSLogo} alt='tgs logo' width='54px' height='38.89px' />

			{/* Application Tracker Text */}
			<Typography
				variant='h6'
				sx={{
					fontFamily: 'var(--fontFamily)',
					fontSize: '1.25rem',
					fontWeight: 500,
					lineHeight: '32px',
					letterSpacing: '0.15px',
					textAlign: 'center',
					color: 'var(--text-primary, #000000DE)',
					width: '178px',
					height: '32px',
					marginLeft: '8px', // Space between the logo and text
					opacity: 1,
					backgroundColor: 'transparent',
				}}
			>
				Application Tracker
			</Typography>
		</Box>
	);
};
