// SettingsSidebar.js
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import { ArrowBack, AccountCircle, People } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const SettingsSidebar = ({ isAdmin }) => {
	return (
		<Drawer
			variant='permanent'
			sx={{
				width: 240,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: 240,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				},
			}}
		>
			{/* Back to Application Tracker */}
			<Box>
				<ListItem button component={Link} to='/'>
					<ListItemIcon>
						<ArrowBack />
					</ListItemIcon>
					<ListItemText primary='Back to Application Tracker' />
				</ListItem>

				<List>
					{/* Account always visible */}
					<ListItem button component={Link} to='/settings'>
						<ListItemIcon>
							<AccountCircle />
						</ListItemIcon>
						<ListItemText primary='Account' />
					</ListItem>

					{/* User Management only visible to admins */}
					{isAdmin && (
						<ListItem button component={Link} to='/settings/user-management'>
							<ListItemIcon>
								<People />
							</ListItemIcon>
							<ListItemText primary='User Management' />
						</ListItem>
					)}
				</List>
			</Box>
		</Drawer>
	);
};

export default SettingsSidebar;
