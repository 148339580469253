import { useEffect, useState } from 'react';
import axios from 'axios';
import {
	Box,
	Typography,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Select,
	MenuItem,
	IconButton,
	Menu,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // For the three-dot button
import { Pagination } from './Pagination';
import { API_GetJobs, API_UpdateJobStatus } from '../Assets/ApiUrlContainer';

const JobsPage = () => {
	const [jobs, setJobs] = useState([]); // State to store job data
	const [loading, setLoading] = useState(true); // State to show loading indicator
	const [statusFilter, setStatusFilter] = useState('All'); // State for status filter
	const [searchTerm, setSearchTerm] = useState(''); // State for search query
	const [anchorEl, setAnchorEl] = useState(null); // State to control the opening of the menu
	const [currentJobId, setCurrentJobId] = useState(null); // To keep track of which job's status to change
	const [currentStatus, setCurrentStatus] = useState(null); // To store the current status of the job

	// Pagination state
	const [listDisplayStartingIndex, setListDisplayStartingIndex] = useState(0);
	const [listDisplayLimit, setListDisplayLimit] = useState(0);

	const navigate = useNavigate();

	useEffect(() => {
		// Fetch jobs from the backend
		const fetchJobs = async () => {
			try {
				const response = await axios.get(API_GetJobs);
				setJobs(response.data);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching jobs:', error);
				setLoading(false);
			}
		};

		fetchJobs(); // Call fetchJobs on component mount
	}, []);

	if (loading) {
		return <Typography>Loading...</Typography>; // Show loading state
	}

	const handleStatusChange = (event) => {
		setStatusFilter(event.target.value); // Update status filter
	};

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value); // Update search query
		console.log('Search Term Updated:', event.target.value);
	};

	const handleMenuClick = (event, jobId, status) => {
		setAnchorEl(event.currentTarget); // Open the menu
		setCurrentJobId(jobId); // Set the jobId of the clicked job
		setCurrentStatus(status); // Set the current status for that job
	};

	const handleMenuClose = () => {
		setAnchorEl(null); // Close the menu
	};

	const handleStatusUpdate = async (newStatus) => {
		try {
			// Update the job status locally
			const updatedJobs = jobs.map((job) =>
				job.listingId === currentJobId ? { ...job, status: newStatus } : job
			);
			setJobs(updatedJobs);

			// Send the PUT request to update the job status in the backend
			await axios.put(API_UpdateJobStatus, {
				listingId: currentJobId,
				status: newStatus,
			});

			setAnchorEl(null); // Close the menu after updating status
			console.log(`Job ID ${currentJobId} status updated to ${newStatus}`);
		} catch (error) {
			console.error('Error updating job status:', error);
			setAnchorEl(null); // Close the menu even if the update fails
		}
	};

	// Filter jobs based on the search term and the selected status
	const filteredJobs = jobs.filter((job) => {
		// Apply search filter (job title contains the search term)
		const matchesSearch = job.title.toLowerCase().includes(searchTerm.toLowerCase());

		// Apply status filter if it's not "All"
		const matchesStatus = statusFilter === 'All' || job.status === statusFilter;

		// Return jobs that match both filters
		return matchesSearch && matchesStatus;
	});

	const handlePageChange = (newPositionIndex) => setListDisplayStartingIndex(newPositionIndex);
	const handlePageListLimitChange = (newListDisplayLimit) => setListDisplayLimit(newListDisplayLimit);

	return (
		<div className='tgs-content'>
			<Box sx={{ padding: 2 }} className='jobsListContainer'>
				<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginBottom: 2 }}>
					<Typography variant='h4'>Jobs</Typography>
					<IconButton>
						<SyncIcon />
					</IconButton>
				</Box>

				<Box display='flex' alignItems='center' gap={2} sx={{ marginBottom: 2 }}>
					<TextField
						label='Search'
						variant='outlined'
						value={searchTerm}
						onChange={handleSearchChange} // Update search query on input change
						sx={{
							width: '700px',
							backgroundColor: '#f0f0f0',
							'& .MuiSelect-select.MuiSelect-select': {
								color: statusFilter === '' ? '#9e9e9e' : 'black', // Grey out the text if no status is selected
							},
						}}
					/>
					<Select
						value={statusFilter}
						onChange={handleStatusChange}
						variant='outlined'
						sx={{ width: 150, backgroundColor: '#f5f5f5' }}
					>
						<MenuItem value='All'>All</MenuItem>
						<MenuItem value='Open'>Open</MenuItem>
						<MenuItem value='Closed'>Closed</MenuItem>
						<MenuItem value='Active'>Active</MenuItem>
						<MenuItem value='Paused'>Paused</MenuItem>
					</Select>
				</Box>

				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Role</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Rate</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Location</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredJobs.slice(listDisplayStartingIndex, listDisplayLimit).map((job) => (
								<TableRow key={job.listingId} hover style={{ cursor: 'pointer' }}>
									<TableCell>
										{/* Only the title is a clickable link */}
										<Link
											to={`/jobs/${job.listingId}`}
											style={{ textDecoration: 'none', color: 'inherit' }}
											className='jobName'
										>
											{job.title || 'N/A'}
										</Link>
									</TableCell>
									<TableCell>{job.status || 'N/A'}</TableCell>
									<TableCell>{job.rate || '-'}</TableCell>
									<TableCell>{job.type || 'N/A'}</TableCell>
									<TableCell>{job.location || 'N/A'}</TableCell>
									<TableCell>
										<IconButton onClick={(e) => handleMenuClick(e, job.listingId, job.status)}>
											<MoreVertIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

				{/* Menu to select new status */}
				<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
					<MenuItem onClick={() => handleStatusUpdate('Open')}>Open</MenuItem>
					<MenuItem onClick={() => handleStatusUpdate('Closed')}>Closed</MenuItem>
					<MenuItem onClick={() => handleStatusUpdate('Active')}>Active</MenuItem>
					<MenuItem onClick={() => handleStatusUpdate('Paused')}>Paused</MenuItem>
				</Menu>

				<Pagination
					totalNumItems={filteredJobs.length}
					onPageChangeNewPosition={handlePageChange}
					onRowsChangeNewDisplayLimit={handlePageListLimitChange}
				/>
			</Box>
		</div>
	);
};

export default JobsPage;
